import React from 'react'
import reviewsStyles from './reviews.module.scss'
import { StaticImage } from "gatsby-plugin-image"

const Reviews = (props) => {

    return (
        <section className={reviewsStyles.reviews}>

            <div className={reviewsStyles.reviews__waves}>
                <div className={reviewsStyles.reviews__waves__wave}></div>
            </div>

            <div className={"container " + reviewsStyles.reviews__container}>
                <div className={reviewsStyles.reviews__item}>
                    <div className={reviewsStyles.reviews__item__content}>
                        <blockquote>"The easiest decision I've ever made, I signed up and have never had to do anything since, they manage everything!"</blockquote>
                        <cite>
                            <StaticImage
                                src="../images/ali-purkis-video.jpg"
                                alt="Alasdair Purkis Video"
                                placeholder="tracedSVG"
                            />
                            <p>Alasdair Purkis Video<span>Customer since 2018</span></p>
                        </cite>
                    </div>
                </div>
                <div className={reviewsStyles.reviews__item}>
                    <div className={reviewsStyles.reviews__item__content}>
                        <blockquote>"Always go above and beyond! I've used Host3 now for over 11 years and I wouldn't ever go anywhere else"</blockquote>
                        <cite>
                            <StaticImage
                                src="../images/solo-heating-installations.jpg"
                                alt="Solo Heating Installations"
                                placeholder="tracedSVG"
                            />
                            <p>Solo Heating Installations<span>Customer since 2009</span></p>
                        </cite>
                    </div>
                </div>
            </div>

            <div className={reviewsStyles.reviews__waves}>
                <div className={reviewsStyles.reviews__waves__wave}></div>
            </div>

        </section>
    )
}

export default Reviews